import DataManager from './DataManager';
var refreshIntervalTimer;

class ApiManager {
  // localhost
  //npm run start
  //npm run buld

  // prerelease
  //npm run start:staging
  //npm run buld:staging

  // production
  //npm run start:production
  //npm run build:production
  static protocol = process.env.REACT_APP_PROTOCOL;
  static protocolWs = process.env.REACT_APP_PROTOCOL_WS;
  static authority = process.env.REACT_APP_API_URL;

  static fetchEndpoint = `${this.protocol}://${this.authority}/api/`;

  static async basic(request) {
    let params = {
      method: request.method,
      headers: { 'Content-Type': 'application/json' },
      };

    if (request.body) params.body = JSON.stringify(request.body);
    if (request.headers)
          params.headers = { ...params.headers, ...request.headers };

    let response = await fetch(this.fetchEndpoint + request.endpoint, params);

    if (response.ok) {
        let json = await response.json();
        return json;
    } else if (request.endpoint === 'TwoFactor/VerifyApp' || request.endpoint === 'User/SignUp') {
        return response;
    }

    return undefined;
  }

  static async refereshToken() {
      let userInfo = DataManager.retrieveUser();
      let response = await this.basic({
          method: 'POST',
          body: {
              accessToken: userInfo.token,
              refreshToken: userInfo.refreshToken
          },
          endpoint: 'User/RefreshToken',
          headers: { Authorization: `Bearer ${userInfo.token}` }
      });

      if (response) {
          userInfo.token = response.accessToken;
          userInfo.refreshToken = response.refreshToken;
          DataManager.updateUser(userInfo);
          return userInfo;
      }

      return undefined;
  }

  static retrieveToken() {
    let userInfo = DataManager.retrieveUser();
    return userInfo.token;
  }

  static async login(logData) {
    let response = await this.basic({
      method: 'POST',
      body: logData,
      endpoint: 'User/login'
    });
    if (response) {
      if (response.providers) {
        return response;
      }
      response.user.token = response.accessToken;
      response.user.refreshToken = response.refreshToken;
      response.user.expiration = response.expiration; //1669112129
      response.user.enabledTwofa = false;

      clearInterval(refreshIntervalTimer);
      //����� ������ 25 ����� ��������� �����
      refreshIntervalTimer = setInterval(() => this.refereshToken(), 1500000);

      return response.user;
    }
    return undefined;
  }

  static async registration(logData) {
    try {
      let response = await this.basic({
        method: 'POST',
        body: logData,
        endpoint: 'User/SignUp',
      });
      
      if (response.status === 500) {
        let json = await response.json();

        return json;
      }

      return response;
    } catch (error) {
      console.log('error', error);
    }
  }

  static async getUserInfo() {
    let response = await this.basic({
      method: 'GET',
      endpoint: 'User/GetInfo',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response.message;
  }

  static async loginTwofa(logData) {
    let response = await this.basic({
      method: 'POST',
      body: logData,
      endpoint: 'User/loginTFA',
    });
    if (response) {
      response.user.token = response.accessToken;
      response.user.refreshToken = response.refreshToken;
      response.user.expiration = response.expiration; //1669112129
      response.user.enabledTwofa = true;
      return response.user;
    }
    return undefined;
  }

  static async allVps() {
    let response = await this.basic({
      method: 'GET',
      endpoint: 'Vps/GetAll',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async getListCountry() {
    let response = await this.basic({
      method: 'GET',
      endpoint: 'ProxmoxNode/GetListCountry?isWritableOnly=true',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
      return response;
  }

  static async getListPaidServices() {
    let response = await this.basic({
      method: 'GET',
      endpoint: 'Billing/PaidServices',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
      return response;
  }

  static async myReferals() {
    let response = await this.basic({
      method: 'GET',
      endpoint: 'User/GetMyReferals',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async getStatusTFA() {
    const response = await this.basic({
      method: 'GET',
      endpoint: 'TwoFactor/GetStatusTFA',
      headers: {
        Authorization: `Bearer ${this.retrieveToken()}`,
      },
    });
    return response;
  }

  static async enableTwofa(data) {
    let response = await this.basic({
      method: 'GET',
      body: data,
      endpoint: 'TwoFactor/EnableTFA',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async disableTwofa(data) {
    let response = await this.basic({
      method: 'GET',
      body: data,
      endpoint: 'TwoFactor/DisableTFA',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async verifyApp(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'TwoFactor/VerifyApp',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async addTwofaApp(data) {
    let response = await this.basic({
      method: 'GET',
      body: data,
      endpoint: 'TwoFactor/AddTFAApp',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async removeTwofaApp(data) {
    let response = await this.basic({
      method: 'GET',
      body: data,
      endpoint: 'TwoFactor/RemoveTFAApp',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async setPassword(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Vps/SetPassword',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async startVps(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Vps/Start',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async stopVps(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Vps/Stop',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async rebootVps(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Vps/Reboot',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
    }

  static async rebootAdminVps(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Vps/AdminReboot',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async toggleAutoRenew(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Vps/SetupAutoRenewal',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async listUsers(query, page) {
    let response = await this.basic({
      method: 'GET',
      endpoint:
        'Admin/GetList?pageNumber=' + page + '&pageSize=15&search=' + query,
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async deleteVps(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Vps/Remove',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async addVps(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Vps/Add',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async createUser(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Admin/Create',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async updateUser(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Admin/Update',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async deleteUsers(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Admin/Delete',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async resetUnpaidBalance(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Admin/ResetUnpaidBalance',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async getListTemplates() {
    const response = await this.basic({
      method: 'GET',
      endpoint: 'ProxmoxTemlate/GetListTemplates',
      headers: {
        Authorization: `Bearer ${this.retrieveToken()}`,
      },
    });
    return response;
  }

  static async getCalculatorInfo() {
    const response = await this.basic({
      method: 'GET',
      endpoint: 'Configurator/GetCalculatorInfo',
    });
    return response;
  }

  static async editVps(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Vps/Edit',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async createBill(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Billing/CreateBill',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async updateSubscription(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Billing/UpdateSubscription',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async calculatePrice(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Billing/CalculatePrice',
    });
    return response;
  }

  static async depositBalance(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Billing/RefillBalance',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static notificationWebsocket() {
    const websocket = new WebSocket(
        `${this.protocolWs}://${this.authority}/api/Notification/WebSocket?access_token=${this.retrieveToken()}`
    );

    return websocket;
  }

  static async saveVPSName(logData) {
    let response = await this.basic({
      method: 'POST',
      body: logData,
      endpoint: 'Vps/EditName',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async saveSSH(logData) {
    let response = await this.basic({
      method: 'POST',
      body: logData,
      endpoint: 'Vps/SetSSH',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async saveChatId(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Telegram/Insert',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async resetPassword(data) {
    let response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'User/ResetPassword',
      headers: { Authorization: `Bearer ${this.retrieveToken()}` },
    });
    return response;
  }

  static async getListNodes() {
    const response = await this.basic({
      method: 'GET',
      endpoint: 'ProxmoxNode/GetList',
      headers: {
        Authorization: `Bearer ${this.retrieveToken()}`,
      },
    });
    return response;
  }

  static async getNodesNamesList() {
    const response = await this.basic({
      method: 'GET',
      endpoint: 'Statistic/GetNodesNamesList',
      headers: {
        Authorization: `Bearer ${this.retrieveToken()}`,
      },
    });
    return response;
  }

  static async statisticList(data) {
    const response = await this.basic({
      method: 'POST',
      body: data,
      endpoint: 'Statistic/StatisticList',
      headers: {
        Authorization: `Bearer ${this.retrieveToken()}`,
      },
    });
    return response;
  }
}

export default ApiManager;
