import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { FormControlLabel, FormGroup, Checkbox } from '@mui/material/';
import ApiManager from '../../../ApiManager';
import useConfigurationStore from '../../../stores/configuration';

export default function SelectPaidServices() {
  const [allPaidServices, setAllPaidServices] = useState(null);
  const [allPaidServicesKeys, setAllPaidServicesKeys] = useState(null);
  const paidServices = useConfigurationStore((state) => state.paidServices);
  const setPaidSevices = useConfigurationStore((state) => state.setPaidServices);
  const setStopDrag = useConfigurationStore((state) => state.setStopDrag);
  const defaultPaidServices = '';

  const handleChange = (event) => {
      if (event.target.checked) {
          setPaidSevices(paidServices + event.target.name + ";");
      }
      else {
          setPaidSevices(paidServices.replace(event.target.name + ";", ''));
      }
      setStopDrag(true);
  };

  useEffect(() => {
    (async () => {
      let paidServicesData = await ApiManager.getListPaidServices();
      setAllPaidServices(paidServicesData);
      setAllPaidServicesKeys(Object.keys(paidServicesData));
      setPaidSevices(defaultPaidServices);
    })();
  }, [setAllPaidServices, setAllPaidServicesKeys, setPaidSevices]);
  
  const getPaidServiceItem = (key) => {
    return <FormControlLabel 
      key={key}
      sx={{ minWidth: 190 }}
      control={ 
        <Checkbox
          sx={{ color: '#2A73DE !important', '&.Mui-checked': { color: '#2A73DE !important' }}}
          name={key}
          onChange={handleChange} />}
          label={allPaidServices[key]} />;
  };

  return (
    <Box sx={{ minWidth: 190 }}>
        <FormGroup name = "SelectPaidServices">
          {allPaidServicesKeys && allPaidServicesKeys.map((key) => getPaidServiceItem(key))}
        </FormGroup>
    </Box>
  );
}
