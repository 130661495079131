import { useState, useRef, useEffect } from 'react';
import ApiManager from '../ApiManager';

const useWs = (
    setNotificationRenewModal,
    setNotificationAutoRenew,
    setNeedDepositValue,
    setUpdatedExpirationIds,
    updateUserInfo,
    setUpdatedStatusIds,
    setUpdatedPortIds,
    setUpdatedCountryIds
) => {
    const [waitModal, setWaitModal] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const ws = useRef(null);

    useEffect(() => {
        const socket = ApiManager.notificationWebsocket();
        socket.onopen = function () {
            //console.log('���������� �����������');
            setIsReady(true);
        };
        socket.onmessage = function (event) {
            var wsData = JSON.parse(event.data);

            switch (wsData.type) {
                case 'autoRenewal':
                    if (!waitModal) {
                        setNotificationRenewModal(true);
                        setNotificationAutoRenew(wsData);
                        setNeedDepositValue(wsData.someObject);
                        setWaitModal(true);

                        // **** 60 sec disabled modal ****
                        setTimeout(() => setWaitModal(false), 60000);
                    }
                    break;
                case 'vpsExpiration':
                    setUpdatedExpirationIds({
                        id: wsData.message,
                        expirationDate: wsData.someObject,
                    });
                    break;
                case 'userBalance':
                    updateUserInfo(true);
                    break;
                case 'vpsStatus':
                    setUpdatedStatusIds({
                        id: wsData.message,
                        status: wsData.someObject,
                    });
                    break;
                case 'vpsPort':
                    setUpdatedPortIds({
                        id: wsData.message,
                        port: wsData.someObject,
                    });
                    break;
                case 'vpsCountry':
                    setUpdatedCountryIds({
                        id: wsData.message,
                        country: wsData.someObject,
                    });
                    break;
                default:
                    break;
            }
        };
        socket.onerror = function () {
            //console.error('������ WebSocket');
            socket.close(); // ��������� ����������� � �������
        };

        socket.onclose = function () {
            //console.log('���������� �������');
            setIsReady(false);
        };

        ws.current = socket

        return () => {
            socket.close()
        }
    }, [])

    // bind is needed to make sure `send` references correct `this`
    return [isReady]
}

export default useWs;